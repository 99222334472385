<template>
  <div>
    <headers />
    <div class="mb row" style="padding:15px;padding-top: 90px !important;">
      <div class="col-12">
      <div class="tab-box" >
                  <ul class="tab-head nav nav-tabs">
                    <li v-for="(item,index) in groups" :key="index" @click="tabg(item.key)" v-bind:class="[cur_group==item.key ? 'nav-item active show':'nav-item']">
                      <a data-toggle="tab"  class="nav-link" aria-expanded="true">{{item.val}}</a>
                    </li>
                    <li @click="tabg('adv')" v-bind:class="[cur_group=='adv' ? 'nav-item active show':'nav-item']">
                      <a data-toggle="tab" class="nav-link" aria-expanded="true">{{lan.all}}</a>
                    </li>
                    <li @click="tabg('adv_all')" v-bind:class="[cur_group=='adv_all' ? 'nav-item active show':'nav-item']">
                      <a data-toggle="tab" class="nav-link" aria-expanded="true">{{lan.all_charts}}</a>
                    </li>

            </ul>
      </div>
        <div class="tab-box2 " v-if="cur_group!='adv' && cur_group!='adv_all'" >
          <ul class="tab-head nav nav-tabs">
            
            <li class="nav-item" v-for="(item,index) in cur_menus" :key="index" data-id="tc1" @click="tab(index,item)" v-bind:class="[curindex==index ? 'nav-item active show':'nav-item']">
             <a data-toggle="tab"  class="nav-link" aria-expanded="true">{{item.split("_")[0]}}</a>
            </li>
          </ul>
          <div class="tab-content" style="background:#fff">
        
            <div class="col-12">
              <div class="card-box">
                  <div style="padding:15px;" v-html="current_help"></div>                
                  <iframe class="embed-responsive-item" :src="current_chart" loading="lazy">
                  </iframe> 
              </div>
            </div>
          </div>                           
        </div>
        <div class="tab-box2 " v-if="cur_group=='adv'" >
          <iframe class="embed-responsive-item" :src="current_chart" loading="lazy"></iframe> 
        </div>
        <div class="tab-box2 " v-if="cur_group=='adv_all'" >
          <iframe class="embed-responsive-item" v-for="(item,index) in current_chart_data" :key="index" :src="item" loading="lazy"></iframe> 
        </div>
      </div>
  
    </div>
    
  </div>
</template>

<script>
import axios from 'axios';
//import ListArticleLease from '@/components/ListArticleLease.vue'
import router from '@/router'
import { inject } from 'vue';
import Headers from '@/components/Headers.vue'

export default {
  name: 'MarketInfo',
  components: {
    Headers  
  },
  data() {
    const { currentRoute } = router
    return {
      curindex:0,
      tolink:"/"+currentRoute.value.query.tolink,
      curlink: encodeURIComponent(window.location.href.split('#')[1]),
      current_chart:'',
      current_chart_data:[],
      current_help:'',
      chart_data:[],
      cur_group:1,
      groups:[],
      cur_menus:[],
      lan:inject('global').language_data,lan_help:inject('global').language_help_data,
    } 	
  },
  mounted() {   
    this.getMarketInfo();
  },
  methods: {   
    getMarketInfo(){
      var that=this;
      axios
        .get(process.env.VUE_APP_API2_URL_BASE+'/getAiMarketInfoChart?token='+localStorage.getItem('id_token'))
        .then((dd) => {
          that.chart_data = dd.data.data;
          that.groups=that.chart_data.groups;
          that.current_chart=that.chart_data.chart[0];
          that.current_chart_data=that.chart_data.chart;
          that.cur_menus=that.chart_data.names.filter(element => {
            
           return element.indexOf("_"+this.cur_group)>-1
           
          });
          console.log(that.cur_menus)
          that.current_help=that.chart_data.helps[0];
        })
        .catch(error => {
        
          console.log(error)
        });
    },
    tabg:function(tid){
      this.cur_group=tid;
      if(tid=='adv'){
        this.current_chart='https://charts.cbreai.com/stock_index/?token='+localStorage.getItem('id_token')+'&language=en'
      }else if(tid=='adv_all'){
        this.current_chart_data=[
        'https://charts.cbreai.com/sub_area_vacancy/?location=tokyo&language=jp&token='+localStorage.getItem('id_token'),
        'https://charts.cbreai.com/sub_area_facenew/?location=tokyo&language=jp&token='+localStorage.getItem('id_token'),
        'https://charts.cbreai.com/sub_area_newsupply/?location=tokyo&language=jp&token='+localStorage.getItem('id_token'),
        'https://charts.cbreai.com/sub_area_supply_demand_vacancy/?location=tokyo&language=jp&token='+localStorage.getItem('id_token'),
        'https://charts.cbreai.com/sub_area_assumed_achievable/?location=tokyo&language=jp&token='+localStorage.getItem('id_token'),
        'https://charts.cbreai.com/sub_sub_area_vacancy/?location=tokyo&language=jp&token='+localStorage.getItem('id_token'),
        'https://charts.cbreai.com/sub_sub_area_face_new/?location=tokyo&language=jp&token='+localStorage.getItem('id_token')     
      ];
      console.log(this.current_chart_data)
      }else{
        this.cur_menus=this.chart_data.names.filter(element => {
           
             return element.indexOf("_"+this.cur_group)>-1
          
          });
        }
    },
    tab:function(tid,item){
      this.curindex=tid;
      let cc=this.chart_data.names.indexOf(item);
      this.current_chart=this.chart_data.chart[cc] !=undefined ? this.chart_data.chart[cc]:'';
      this.current_help=this.chart_data.helps[cc] !=undefined ? this.chart_data.helps[cc]:'';
    },
   

  },beforeUnmount () {
    window.removeEventListener('pagehide', this.backListener)
  },

 
}
</script>
<style lang="scss">
.rent-list .table-striped tr td:nth-child(1){    text-decoration: underline;}
.btn-back {
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 255;
}

.build-article .card-box {
  max-height: 1460px;
  overflow-y: auto;
}

.build-article .row > div {
  display: flex;
  flex-direction: column;
}

.build-article .card-box {
  flex: 1;
}

// rader-chart
.build-article .embed-responsive-1by1 {
  max-height: 550px;
}

.build-article .row .btn-toolbar {
  flex-direction: row;
}

.other-comments-container {
  position: relative;
}

.other-item {
  text-align: left;
  display: block;
  flex: 1;
  height: 230px;
  overflow-y: auto;
  padding: .5rem 1rem;
  word-break: break-all;
}

.other-control {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1.5rem .25rem;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: .15s;
}

.other-control-prev {
  left: -1.5rem;
}

.other-control-next {
  right: -1.5rem;
}
.piclist .carousel{
  height: 640px;
  width: 90%;
}
.carousel__prev, .carousel__next{
  background: #012a2d;
}
.carousel__pagination-button--active{
  color: #012a2d;
  background: #012a2d;
}
.carousel__slide{margin: 0 auto;width: 100% !important;}
.table-borderless tr{border-bottom: 1px solid #ddd;}
.carousel__slide img{
  max-width: 100%;
  max-height: 100%;
}
.responsive-table-plugin tbody th{
  width: 25%;
  font-weight: bolder !important;
}
.market_url{width: 100%;height: 800px;border: 0;}
.embed-responsive{height:540px;}
.embed-responsive-item{width: 100%;height:700px;border:0;}
.popup-modal {
    display: block;
  }
  .popup-bg {
    background: rgba(0, 0, 0, .25);
    height: 100vh;
    left: 0;
    top: 0;
    position: fixed;
    width: 100vw;
    z-index: 9;
  }

  .popup {
    background: #fff;
    border-radius: .15rem;
    box-shadow: 0 .15rem .5rem rgba(0, 0, 0, .25);
    left: 50% !important;
    max-height: 85vh;
    min-height: 750px;
    width: 850px;
    position: fixed;

    transform: translateX(-50%) !important;
    z-index: 16;
  }

  .popup iframe {
    width: 100%;
  }
 
  .help{position: relative;    top: -30px;
    left: 80%;    display: inline-block;}
.help-box{margin-top:10%;width: 80%;}
.tab-box2{
.nav{width:15%;display:block;float:left;border:1px solid #ddd; margin-top: 20px;   height: 600px;
    overflow-y: auto;}
.nav li{border-bottom:1px solid #ddd;cursor:pointer;}
.nav li:hover,.nav li.active,.nav li.active .nav-link{background:#003f2d !important;color:#fff !important;    border-radius: 0;
    border: 0;}
.tab-content{width:81%;display:block;float:left;margin-left:3%;padding-top:10px;}
}
</style>